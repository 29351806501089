import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { normalizeThemeUIColors, filterStyles } from "@lekoarts/gatsby-theme-specimens";
import ProfileCard from '../components/ProfileCard';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "welcome-to-rynkov-family"
    }}>{`Welcome to Rynkov Family`}</h1>
    <Alert type="success" mdxType="Alert">In love with JavaScript</Alert>
    <h2 {...{
      "id": "who-we-are"
    }}>{`Who we are?`}</h2>
    <p>{`We are `}<inlineCode parentName="p">{`Fullstack`}</inlineCode>{` Developers with a focus on the latest technologies as well as supporting legacy systems.`}</p>
    <h2 {...{
      "id": "about"
    }}>{`About`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<ProfileCard
  name = 'Yuri Rynkov'
  linkedin = 'yuri-rynkov-35995579'
  github = 'deepq'
  website ='https://rynkov.eu'
  position = 'Fullstack Developer'
/>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<ProfileCard
  name = 'Avi Rynkov'
  linkedin = 'avraam-r'
  github = 'avirynkov'
  website = 'https://avirynkov.dev'
  position = 'Fullstack Developer'
/>
`}</code></pre>
    <h2 {...{
      "id": "soon"
    }}>{`Soon...`}</h2>
    <ul>
      <li parentName="ul">{`Pet projects section`}</li>
      <li parentName="ul">{`A litte bit more UI here`}</li>
      <li parentName="ul">{`Tags, banner (social)`}</li>
      <li parentName="ul">{`Improve Service Worker`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://rynkov.eu"
      }}><strong parentName="a">{`rynkov.eu`}</strong></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      